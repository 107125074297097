import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import config from "./config";
import "./assets/main.css";
import { PENDO_KEY } from "./constants/commonConstants";

Vue.config.productionTip = false;

config.init().then(() => {
  import("./router").then((r) => {
    const tempVue = new Vue({
      router: r.default,
      store,
      render: (h) => h(App),
    });
    (function pendoFunction1(apiKey) {
      (function pendoFunction2(p, e, n, d, o) {
        let w;
        let x;
        p[d] = p[d] || {};
        o = p[d];
        o._q = o._q || [];
        const v = [
          "initialize",
          "identify",
          "updateOptions",
          "pageLoad",
          "track",
        ];
        for (w = 0, x = v.length; w < x; ++w)
          (function pendoFunction3(m) {
            o[m] =
              o[m] ||
              function pendoFunction4(...args) {
                o._q[m === v[0] ? "unshift" : "push"](
                  [m].concat([].slice.call(args, 0))
                );
              };
          })(v[w]);
        const y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        const z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, "script", "pendo");
    })(PENDO_KEY);
    tempVue.$mount("#app");
  });
});

/* eslint-disable no-undef */

import axios from "axios";

// to be moved in process.env

const DEFAULT_LIVEBOARD_CONFIG = {
    MNID1: {
        enabledDashboards: ["PERFORMANCE"],
        PERFORMANCE: {
            LIVEBOARD_TABS: {
                DELIVERY: {
                    GUID: "da6efab9-75cf-46ec-9184-42750c850aee",
                },
                CONVERSIONS: {
                    GUID: "da6efab9-75cf-46ec-9184-42750c850aee",
                },
                REPORTING: {
                    GUID: "da6efab9-75cf-46ec-9184-42750c850aee",
                },
            },
        },
    },
    JMSD1: {
        enabledDashboards: ["PERFORMANCE", "LINEAR_INSIGHTS"],
        PERFORMANCE: {
            LIVEBOARD_TABS: {
                DELIVERY: {
                    GUID: "718d3bc6-dc52-45d8-984a-87ca9dd397a7",
                },
                CONVERSIONS: {
                    GUID: "1c3fdc41-9f2b-4631-bed0-2c5390f0cba9",
                },
                REPORTING: {
                    GUID: "6e8399c1-6e14-4339-a9b2-0d670e006ab2",
                },
            },
        },
        LINEAR_INSIGHTS: {
            LIVEBOARD_TABS: {
                DELIVERY: {
                    GUID: "718d3bc6-dc52-45d8-984a-87ca9dd397a7",
                },
                CONVERSIONS: {
                    GUID: "1c3fdc41-9f2b-4631-bed0-2c5390f0cba9",
                },
                REPORTING: {
                    GUID: "6e8399c1-6e14-4339-a9b2-0d670e006ab2",
                },
                COMPETITIVE_ANALYSIS: {
                    GUID: "",
                },

                CROSS_PLATFORM: {
                    GUID: "",
                },
                PLANNING: {
                    GUID: "",
                },
            },
        },
    },
};
const config = {
    async init() {
        // Load some configs dynamically
        //
        // This solves the problem of baking a docker image with a static JS build
        // but still change certain config values dynamically based on the
        // environment on app startup.
        //
        // ALL per-ENV vars *MUST* be loaded from this endpoint!
        return axios
            .get("/api/config.json")
            .then((res) => {
                Object.assign(config, res.data);
                config.BIT_SERVER_API_URL ||=
                    process.env.VUE_APP_BIT_SERVER_API_URL;
                config.liveboardConfig ||= DEFAULT_LIVEBOARD_CONFIG;
            })
            .catch((e) => {
                // local from env in case api fails on local
                console.error("Failed to load /api/config.json:", e);
                config.BIT_SERVER_API_URL =
                    process.env.VUE_APP_BIT_SERVER_API_URL;
                config.liveboardConfig = DEFAULT_LIVEBOARD_CONFIG;
            });
    },
};

export default config;

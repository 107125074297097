import clientData from "../store/data/client-solutions.json";
const CLIENT_SOLUTION_KEY_ORDER = [
	"Agency",
	"Advertiser",
	"Solution ID",
	"White Label",
	"Solution",
];
const ALL_SOLUTIONS_KEY_ORDER = [
	"Solution ID",
	"Solution",
	"UI Tab",
	"Liveboard Name",
	"Liveboard GUID",
	"Description",
	"Period",
	"Conversion Window",
	"Worksheet",
	"Search Data UI Selection",
];
export const getAllSolutions = async () => {
	const data = [];
	const { solutions = [] } = clientData;
	solutions.forEach((allSolMapping) => {
		const mapArray = [];
		ALL_SOLUTIONS_KEY_ORDER.forEach((key) => {
			mapArray.push(checkForValue(allSolMapping[key]));
		});
		data.push(mapArray);
	});
	return Promise.resolve({ data });
};

export const getClientSolutions = async () => {
	const data = [];
	const { client_solutions_mapping = [] } = clientData;
	client_solutions_mapping.forEach((solMapping) => {
		const mapArray = [];
		CLIENT_SOLUTION_KEY_ORDER.forEach((key) => {
			mapArray.push(checkForValue(solMapping[key]));
		});
		data.push(mapArray);
	});
	return Promise.resolve({ data });
};

const checkForValue = (val) => {
	if (val) {
		return val;
	}
	return "";
};

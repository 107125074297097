export const getSearchData = async (searchUrl, shouldEncode = true) => {
  let dataPayload = {};
  let formatUrl = shouldEncode ? encodeURI(searchUrl) : searchUrl;
  await fetch(formatUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-By": "ThoughtSpot",
    },
    credentials: "include",
  })
    .then((response) => response.json())
    .then((res) => {
      dataPayload = { data: res.data };
    })
    .catch((error) => console.error(`Error getting search data ${error}`));
  return dataPayload;
};

export const getMetaData = async (metaurl) => {
  let dataPayload = {};
  await fetch(encodeURI(metaurl), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-Requested-By": "ThoughtSpot",
    },
    credentials: "include",
  })
    .then((response) => response.json())
    .then((res) => {
      dataPayload = { data: res };
    })
    .catch((error) => console.error(`Error getting search data ${error}`));
  return dataPayload;
};

export const updatePassword = async (userurl) => {
  try {
    const response = await fetch(encodeURI(userurl), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-Requested-By": "ThoughtSpot",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "include",
    });
    const string = await response.text();
    const json = string === "" ? {} : JSON.parse(string);
    return json;
  } catch (e) {
    console.error(`Error updating password ${e}`);
  }
};

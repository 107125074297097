import {
  //MVP_CLIENTS,
  BASE_TS_URL,
  CAMPAIGN_COLUMNS,
  SEARCH_API_URL,
  ALL_WORKSHEETS,
  METADATA_API_URL,
} from "../constants/commonConstants";
import { getSearchData, getMetaData } from "./apiServices";

export const getAdvertisers = async (worksheet, mvpClients, columnConfig) => {
  const { config, columnName } = columnConfig;
  let url = encodeURI(
    `${BASE_TS_URL}${SEARCH_API_URL}?data_source_guid=${worksheet}&query_string=${config}`
  );
  mvpClients.forEach((client) => {
    url += encodeURIComponent(` [${columnName}]."${client}"`);
  });

  return await getSearchData(url, false);
};

export const getCampaigns = async (
  advertiser,
  worksheet,
  campaignConfig = CAMPAIGN_COLUMNS
) => {
  let getSearchDataURL = `${BASE_TS_URL}${SEARCH_API_URL}?`;
  getSearchDataURL += `data_source_guid=${worksheet}`;
  getSearchDataURL += `&query_string=${campaignConfig}="${advertiser}"`;
  return await getSearchData(getSearchDataURL);
};

export const getAllAdvertisers = async () => {
  let all_clients_advertisers;
  for (let worksheet in ALL_WORKSHEETS) {
    let guid = worksheet.guid;
    let columns = worksheet.columns;
    let url = `${BASE_TS_URL}${SEARCH_API_URL}?`;
    url += `data_source_guid=${guid}`;
    url += `&query_string=${columns}`;

    return await getSearchData(url);
  }
  return all_clients_advertisers;
};

export const getMyWorksheets = async () => {
  let url = `${BASE_TS_URL}${METADATA_API_URL}?`;
  url += `type=LOGICAL_TABLE`;
  return await getMetaData(url);
};

import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";
import VuexReset from "@ianwalter/vuex-reset";
import common from "./common";
// options
pathify.options.deep = 2;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common, // common data
  },
  plugins: [pathify.plugin, VuexReset()],
  strict: false,
});

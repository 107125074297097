//import config from "../config";
export const PENDO_KEY = "d2e021ca-ebff-4bbe-4bf3-2e9bdee5380c";
export const PAGE_TYPE = {
	SOLUTIONS: "solutions",
	MY_REPORTS: "my-reports",
	SEARCH_DATA: "search-data",
	HOME: "home",
};

export const PAGE_TAB = {
	[PAGE_TYPE.HOME]: {
		path: PAGE_TYPE.HOME,
		name: "Home",
		className: "home-page",
	},
	[PAGE_TYPE.SOLUTIONS]: {
		path: PAGE_TYPE.SOLUTIONS,
		name: "Solutions",
		className: "solutions",
	},
	
};

export const MVP_CLIENTS = [
	"AdStrategies",
	"Altered State",
	"Big Sea",
	"Jersey Mike's Subs",
	"MNI Motto",
	"Marketwake",
	"Mediamax",
	"Merlino Media",
	"Ron Foth",
	"We Are Guru",
];

// This is a temporary code (hack) to hide certian advertisers that we get in response
// because of bad data in Big-Query
export const HIDE_ADVERTISERS = ["Adstrategies"];

export const BASE_TS_URL = "https://digitalremedy.thoughtspot.cloud";
export const SEARCH_API_URL = "/callosum/v1/tspublic/v1/searchdata";
export const METADATA_API_URL = "/callosum/v1/tspublic/v1/metadata/list";
export const USER_API_URL = "/callosum/v1/tspublic/v1/user/updatepassword";

export const CAMPAIGN_COLUMNS =
	"[Advertiser] [Campaign] [Media Type] [Startdate].'detailed' [Enddate].'detailed'  [Advertiser]";
export const VIZIO_ADVERTISER_COLUMNS =
	"[Advertiser][Line Item Name] [Advertiser]";
export const ALL_SOLUTIONS_COLUMNS =
	"[Id][Solution][UI Tab][Liveboard Name][Liveboard Guid][Image]";
export const CLIENT_SOLUTIONS_COLUMNS = "[Client][Advertiser][Solution Id]";

export const CAMPAIGN_WORKSHEET = "e3f23f5e-7513-4f24-8a8d-03517f148839";
export const ALL_SOLUTIONS_WORKSHEET = "572ef21c-986a-4912-9cb3-709738a75d94";
export const CLIENT_SOLUTIONS_WORKSHEET =
	"014b9dcb-dc87-43a8-b64f-02fca87e4d53";

export const ALL_WORKSHEETS = [
	{
		//id: "95fd5b61-4826-4c99-9ddc-453ce59932c6", // vizio_impressions_with_join_table_view
		id: "95fd5b61-4826-4c99-9ddc-453ce59932c6",
		columnConfig: {
			config: "[Client] [Advertiser]",
			columnName: "Client",
		},
	},
	{
		//id: "518ab025-aa07-4572-ad26-83df39463c13",
		id: "518ab025-aa07-4572-ad26-83df39463c13",
		columnConfig: {
			config: "[Client] [Advertiser]",
			columnName: "Client",
		},
	},
];

export const DASHBOARD_PANEL_LIST = [
	
	{
		backgroundImageUrl: "linear_insights_image",
		tagText: "TV Insights",
		disabled: false,
		id: "TV Insights",
	},
	{
		backgroundImageUrl: "linear_insights_image",
		tagText: "Demo TV Insights",
		disabled: false,
		id: "Demo TV Insights",
	},

];
export const WORKSHEET_TAB_MAPPING = {
	Search: "9fab1550-9c9a-4398-8fa9-bfd4c13f24e9",
	Social: "676208f9-5d98-485e-95a8-98a8eb5a72a1",
	YouTube: "4c6a82f5-8f69-4495-844c-7d734c3ad1a5",
};
export const POSSIBLE_WORSHEET_TABS = [
	"YouTube TV",
	"TV Insights",
	"Premium CTV",
	"Brand Insights",
	"Multi-DSP Access",
	"Search",
	"Social",
	"YouTube",
];
export const SEARCH_SOCIAL_SOLUTION_MAPPING = ["Search", "Social", "YouTube"];
export const WORKSHEET_MAPPING = {
	"TV Insights": ["e1de2fcb-4323-49be-a6e8-62ebdfd55d57"],
	"Demo TV Insights": ["8d8fe73a-3a4c-48af-b30e-972708823af8"],
};
export const CONVERSION_WINDOW_CONFIG = {
	"Premium CTV-Conversions": { value: 31, isDisabled: false },
	"Premium CTV-Footfall": { value: 31, isDisabled: false },
	"Premium CTV-Reporting": { value: 31, isDisabled: false },
	"TV Insights-Conversions": { value: 31, isDisabled: false },
	"TV Insights-Cross-Platform Measurement": { value: 31, isDisabled: false },
	"Action (DSP)-Conversions": { value: 31, isDisabled: true },
	"Action (DSP)-Reporting": { value: 31, isDisabled: true },
};

export const MONTHS = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec",
];

export const WEEK_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const TABS_ORDER_CONFIG = [
	"Overview",
	"Delivery",
	"Conversions",
	"Search",
	"Social",
	"YouTube",
	"Reporting",
	"Footfall",
	"Competitor Analysis",
	"TV Measurement",
	"Cross-Platform Measurement",
	"Planning",
	"Creative",
	"Channel",
];
export const TABS_CONFIG = {
	Delivery: {
		label: "Delivery",
		icon: "forwardArrowIcon",
	},
	Conversions: {
		label: "Conversions",
		icon: "barIcon",
	},
	Reporting: {
		label: "Reporting",
		icon: "shareIcon",
	},
	Footfall: {
		label: "Footfall",
		icon: "footfallIcon",
	},
	"Competitor Analysis": {
		label: "Competitor Analysis",
		icon: "projectGraphIcon",
	},
	"TV Measurement": {
		label: "TV Measurement",
		icon: "tvIcon",
	},
	"Cross-Platform Measurement": {
		label: "Cross-Platform Measurement",
		icon: "crossPlatformIcon",
	},
	Planning: {
		label: "Planning",
		icon: "planningIcon",
	},
	Search: {
		label: "Search",
		icon: "SearchTabIcon",
	},
	Social: {
		label: "Social",
		icon: "socialIcon",
	},
	YouTube: {
		label: "YouTube",
		icon: "youtubeIcon",
	},
	Creative: {
		label: "Creative",
		icon: "creativeIcon",
	},
	Channel: {
		label: "Channel",
		icon: "",
	},
	Overview: {
		label: "Overview",
		icon: "",
	},
};

// Delivery tab - show only date filter
// conversion tab - date filter and conversion days
// cross-platform - campaign, date filter and conversion window filter (from the above worksheet - query advertiser and line item name)
// competiter - only date filter

export const DASHBOARD_OPTION_CONFIG = {
	"TV Insights": {
		Delivery: {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: false,
		},
		Conversions: {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: true,
		},
		Reporting: {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: true,
		},
		"Competitor Analysis": {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: false,
		},
		"Cross-platform Measurement": {
			campaignFilter: true,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: true,
		},
		Planning: {
			campaignFilter: false,
			dateFilter: false,
			mediaTypeFilter: false,
			conversionWindow: false,
		},
	},
	"Demo TV Insights": {
		Delivery: {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: false,
		},
		Conversions: {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: true,
		},
		Reporting: {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: true,
		},
		"Competitor Analysis": {
			campaignFilter: false,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: false,
		},
		"Cross-platform Measurement": {
			campaignFilter: true,
			dateFilter: true,
			mediaTypeFilter: false,
			conversionWindow: true,
		},
		Planning: {
			campaignFilter: false,
			dateFilter: false,
			mediaTypeFilter: false,
			conversionWindow: false,
		},
	},
};

export const DASHBOARD_COLUMNS = {
	"TV Insights": {
		clientColumn: "Client",
		advertiserColumn: "Advertiser",
		conversionWindowColumn: "Days To Convert",
	},
};

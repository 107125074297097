<template>
  <div class="bit-application-container">
    <RouterView />
    <div id="embed"></div>
  </div>
</template>

<script>
import thoughtspotOnLoadMixin from "./mixins/thoughtspot-onload-mixin";
import filterSectionMixin from "./mixins/filter-section-mixin";
import { AuthType, init } from "@thoughtspot/visual-embed-sdk";
import { BASE_TS_URL } from "./constants/commonConstants";
import { getCookie } from "./utils";

export default {
  name: "App",
  mixins: [thoughtspotOnLoadMixin, filterSectionMixin],
  mounted() {
    document.title = "BI - Tool";
    const authCookie = getCookie("user_auth_token");
    if (authCookie) {
      const { username, password } = JSON.parse(authCookie);
      init({
        thoughtSpotHost: BASE_TS_URL,
        authType: AuthType.Basic,
        username: username,
        password: password,
      });
      window.pendo.initialize({
        visitor: {
          id: username,
        },
      });
    }
  },
};
// https://digitalremedy.thoughtspot.cloud/callosum/v1/session/login
</script>

<style lang="scss">
.bit-application-container {
  margin: 0px auto;
}
.bodyFixed {
  overflow: hidden;
}
#embed {
  height: 100vh;
  background-color: #e5e7eb;
}
</style>
